/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const registrations = [
    {
        name: 'Header',
        import: () => import(/* webpackChunkName: "Header" */ '@stories/Widgets/Header/Header'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'Footer',
        import: () => import(/* webpackChunkName: "Footer" */ '@stories/Widgets/Footer/Footer'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'HomeHeroBanner',
        import: () => import(/* webpackChunkName: "HomeHeroBanner" */ '@stories/Widgets/HomeHeroBanner/HomeHeroBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CategoryListing',
        import: () => import(/* webpackChunkName: "CategoryListing" */ '@stories/Widgets/CategoryListing/CategoryListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'BrandLogos',
        import: () => import(/* webpackChunkName: "BrandLogos" */ '@stories/Widgets/BrandLogos/BrandLogos'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ServiceCalloutCard',
        import: () => import(/* webpackChunkName: "ServiceCalloutCard" */ '@stories/Widgets/ServiceCalloutCard/ServiceCalloutCard'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SplitContent',
        import: () => import(/* webpackChunkName: "SplitContent" */ '@stories/Widgets/SplitContent/SplitContent'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'IconText',
        import: () => import(/* webpackChunkName: "IconText" */ '@stories/Widgets/IconText/IconText'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CustomerReviewCarousel',
        import: () => import(/* webpackChunkName: "CustomerReviewCarousel" */ '@stories/Widgets/CustomerReviewCarousel/CustomerReviewCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ImageBanner',
        import: () => import(/* webpackChunkName: "ImageBanner" */ '@stories/Widgets/ImageBanner/ImageBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FeaturedNews',
        import: () => import(/* webpackChunkName: "FeaturedNews" */ '@stories/Widgets/FeaturedNews/FeaturedNews'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FeaturedCards',
        import: () => import(/* webpackChunkName: "FeaturedCards" */ '@stories/Widgets/FeaturedCards/FeaturedCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'BrandPageBanner',
        import: () => import(/* webpackChunkName: "BrandPageBanner" */ '@stories/Widgets/BrandPageBanner/BrandPageBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ProductRangeCards',
        import: () => import(/* webpackChunkName: "ProductRangeCards" */ '@stories/Widgets/ProductRangeCards/ProductRangeCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'OverviewSpecificationGrid',
        import: () => import(/* webpackChunkName: "OverviewSpecificationGrid" */ '@stories/Widgets/OverviewSpecificationGrid/OverviewSpecificationGrid'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ValuationBanner',
        import: () => import(/* webpackChunkName: "ValuationBanner" */ '@stories/Widgets/ValuationBanner/ValuationBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'DealerInformation',
        import: () => import(/* webpackChunkName: "DealerInformation" */ '@stories/Widgets/DealerInformation/DealerInformation'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Video',
        import: () => import(/* webpackChunkName: "Video" */ '@stories/Widgets/Video/Video'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'LandingPageHeroBanner',
        import: () => import(/* webpackChunkName: "LandingPageHeroBanner" */ '@stories/Widgets/LandingPageHeroBanner/LandingPageHeroBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Gallery',
        import: () => import(/* webpackChunkName: "Gallery" */ '@stories/Widgets/Gallery/Gallery'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RichText',
        import: () => import(/* webpackChunkName: "RichText" */ '@stories/Widgets/RichText/RichText'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ProductListing',
        import: () => import(/* webpackChunkName: "ProductListing" */ '@stories/Widgets/ProductListing/ProductListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Shortlist',
        import: () => import(/* webpackChunkName: "Shortlist" */ '@stories/Widgets/Shortlist/Shortlist'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ShortlistCarousel',
        import: () => import(/* webpackChunkName: "ShortlistCarousel" */ '@stories/Widgets/ShortlistCarousel/ShortlistCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ModelListing',
        import: () => import(/* webpackChunkName: "ModelListing" */ '@stories/Widgets/ModelListing/ModelListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RepresentativeAPRTable',
        import: () => import(/* webpackChunkName: "RepresentativeAPRTable" */ '@stories/Widgets/RepresentativeAPRTable/RepresentativeAPRTable'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FAQListing',
        import: () => import(/* webpackChunkName: "FAQListing" */ '@stories/Widgets/FAQListing/FAQListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'OfferBanner',
        import: () => import(/* webpackChunkName: "OfferBanner" */ '@stories/Widgets/OfferBanner/OfferBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'NewsListing',
        import: () => import(/* webpackChunkName: "NewsListing" */ '@stories/Widgets/NewsListing/NewsListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ProfileListing',
        import: () => import(/* webpackChunkName: "ProfileListing" */ '@stories/Widgets/ProfileListing/ProfileListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'NewsBanner',
        import: () => import(/* webpackChunkName: "NewsBanner" */ '@stories/Widgets/NewsBanner/NewsBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ValueYourCarBanner',
        import: () => import(/* webpackChunkName: "ValueYourCarBanner" */ '@stories/Widgets/ValueYourCarBanner/ValueYourCarBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RegEnterBanner',
        import: () => import(/* webpackChunkName: "RegEnterBanner" */ '@stories/Widgets/RegEnterBanner/RegEnterBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Accordion',
        import: () => import(/* webpackChunkName: "Accordion" */ '@stories/Widgets/Accordion/Accordion'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'QuotesListing',
        import: () => import(/* webpackChunkName: "QuotesListing" */ '@stories/Widgets/QuotesListing/QuotesListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'PinnedGoogleMap',
        import: () => import(/* webpackChunkName: "PinnedGoogleMap" */ '@stories/Widgets/PinnedGoogleMap/PinnedGoogleMap'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'InnerContentBanner',
        import: () => import(/* webpackChunkName: "InnerContentBanner" */ '@stories/Widgets/InnerContentBanner/InnerContentBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'BookNowForm',
        import: () => import(/* webpackChunkName: "BookNowForm" */ '@stories/Widgets/BookNowForm/BookNowForm'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'BottomCTABanner',
        import: () => import(/* webpackChunkName: "BottomCTABanner" */ '@stories/Widgets/BottomCTABanner/BottomCTABanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Breadcrumb',
        import: () => import(/* webpackChunkName: "Breadcrumb" */ '@stories/Widgets/Breadcrumb/Breadcrumb'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'DealershipsFinder',
        import: () => import(/* webpackChunkName: "DealershipsFinder" */ '@stories/Widgets/DealershipsFinder/DealershipsFinder'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'EnquireNowForm',
        import: () => import(/* webpackChunkName: "EnquireNowForm" */ '@stories/Widgets/EnquireNowForm/EnquireNowForm'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'EventBanner',
        import: () => import(/* webpackChunkName: "EventBanner" */ '@stories/Widgets/EventBanner/EventBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'EventCarousel',
        import: () => import(/* webpackChunkName: "EventCarousel" */ '@stories/Widgets/EventCarousel/EventCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'EventListing',
        import: () => import(/* webpackChunkName: "EventListing" */ '@stories/Widgets/EventListing/EventListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ExpandingText',
        import: () => import(/* webpackChunkName: "ExpandingText" */ '@stories/Widgets/ExpandingText/ExpandingText'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FinanceCalculator',
        import: () => import(/* webpackChunkName: "FinanceCalculator" */ '@stories/Widgets/FinanceCalculator/FinanceCalculator'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'IconCtaCardList',
        import: () => import(/* webpackChunkName: "IconCtaCardList" */ '@stories/Widgets/IconCtaCardList/IconCtaCardList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ModelCarousel',
        import: () => import(/* webpackChunkName: "ModelCarousel" */ '@stories/Widgets/ModelCarousel/ModelCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'OfferListing',
        import: () => import(/* webpackChunkName: "OfferListing" */ '@stories/Widgets/OfferListing/OfferListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'OpenChargeMap',
        import: () => import(/* webpackChunkName: "OpenChargeMap" */ '@stories/Widgets/OpenChargeMap/OpenChargeMap'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ShareButtons',
        import: () => import(/* webpackChunkName: "ShareButtons" */ '@stories/Widgets/ShareButtons/ShareButtons'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'UsedCarOverview',
        import: () => import(/* webpackChunkName: "UsedCarOverview" */ '@stories/Widgets/UsedCarOverview/UsedCarOverview'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'UspBanner',
        import: () => import(/* webpackChunkName: "UspBanner" */ '@stories/Widgets/UspBanner/UspBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'EmbeddedMap',
        import: () => import(/* webpackChunkName: "EmbeddedMap" */ '@stories/Widgets/EmbeddedMap/EmbeddedMap'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ProfileCarousel',
        import: () => import(/* webpackChunkName: "ProfileCarousel" */ '@stories/Widgets/ProfileCarousel/ProfileCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ReserveVehicleForm',
        import: () => import(/* webpackChunkName: "ReserveVehicleForm" */ '@stories/Widgets/ReserveVehicleForm/ReserveVehicleForm'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SellYourVehicleQuestionnaire',
        import: () => import(/* webpackChunkName: "SellYourVehicleQuestionnaire" */ '@stories/Widgets/SellYourVehicleQuestionnaire/SellYourVehicleQuestionnaire'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'VacancyListing',
        import: () => import(/* webpackChunkName: "VacancyListing" */ '@stories/Widgets/VacancyListing/VacancyListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'VacancyListing2',
        import: () => import(/* webpackChunkName: "VacancyListing2" */ '@stories/Widgets/VacancyListing2/VacancyListing2'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'VehicleSummary',
        import: () => import(/* webpackChunkName: "VehicleSummary" */ '@stories/Widgets/VehicleSummary/VehicleSummary'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ScrollableProductCarousel',
        import: () => import('@stories/Widgets/ScrollableProductCarousel/ScrollableProductCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'LocationEnquiryForm',
        import: () => import(/* webpackChunkName: "LocationEnquiryForm" */ '@stories/Widgets/LocationEnquiryForm/LocationEnquiryForm'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SearchByBudget',
        import: () => import(/* webpackChunkName: "SearchByBudget" */ '@stories/Widgets/SearchByBudget/SearchByBudget'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'InnerPageNavigation',
        import: () => import(/* webpackChunkName: "InnerPageNavigation" */ '@stories/Widgets/InnerPageNavigation/InnerPageNavigation'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CareersEnquiryForm',
        import: () => import(/* webpackChunkName: "CareersEnquiryForm" */ '@stories/Widgets/CareersEnquiryForm/CareersEnquiryForm'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CareersEnquiryForm2',
        import: () => import(/* webpackChunkName: "CareersEnquiryForm" */ '@stories/Widgets/CareersEnquiryForm2/CareersEnquiryForm2'),
        config: {
            hydrate: 'in-view',
        },
    },
];
